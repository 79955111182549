export const mockData = [
  {
    id: 1,
    name: "E-mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    total: 42601,
    date: "2023-12-31",
  },
  {
    id: 2,
    name: "Улсын бүртгэлийн ерөнхий газар",
    serviceName: "NS10023_getLesFondExtendInfo",
    description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
    total: 2636,
    date: "2023-12-31",
  },
  {
    id: 3,
    name: "Улсын бүртгэлийн ерөнхий газар",
    serviceName: "WS100104_getCitizenMarriageInfo",
    description: "Иргэн гэрлэлтийн мэдээлэл дамжуулах сервис",
    total: 224244,
    date: "2023-12-21",
  },
  {
    id: 4,
    name: "Нийгмийн даатгалын ерөнхий газар",
    serviceName: "WS100501_getCitizenSalaryInfo",
    description: "Иргэний нийгмийн даатгалын мэдээлэл дамжуулах сервис",
    total: 2021357,
    date: "2023-12-20",
  },
  {
    id: 5,
    name: "Улсын бүртгэлийн ерөнхий газар",
    serviceName: "WS100101_getCitizenIDCardInfo",
    description: "Иргэний үнэмлэхний мэдээлэл дамжуулах сервис",
    total: 14813626,
    date: "2023-12-19",
  },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
  // {
  //   id: 6,
  //   name: "Улсын бүртгэлийн ерөнхий газар",
  //   serviceName: "NS10023_getLesFondExtendInfo",
  //   description: "Хуулийн этгээдийн үүсгэн байгуулагчийн мэдээлэл",
  //   total: 2636,
  // },
];
export const tableData = [
  {
    id: 1,
    name: "E-Mongolia",
    owner: "Газар зохион байгуулалт,геодези,зураг зүйн газар",
    total: 128,
    date: "2023-12-28",
  },
  {
    id: 2,
    name: "Вишнфанд ББСБ",
    owner: "Улсын бүртгэлийн ерөнхий газар",
    total: 12198,
    date: "2023-12-28",
  },
  {
    id: 3,
    name: "Талимаа",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 1187,
    date: "2023-12-28",
  },
  {
    id: 4,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-29",
  },
  {
    id: 5,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-30",
  },
  {
    id: 6,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-31",
  },
  {
    id: 7,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-19",
  },
  {
    id: 8,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-19",
  },
  {
    id: 9,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-19",
  },
  {
    id: 10,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-19",
  },
  {
    id: 11,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-19",
  },
  {
    id: 12,
    name: "Хөдөлмөр халамж,үйлчилгээний ерөнхий газар",
    owner: "Нийгмийн даатгалын ерөнхий газар",
    total: 12198,
    date: "2023-12-31",
  },
];

export const cardData = [
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 12,
  },
  {
    icon: "iso.svg",
    name: "Гааль, татвар, санхүүгийн мэдээллийн технологийн төв",
    date: "2023.1.3",
    count: 10,
  },
  {
    icon: "iso.svg",
    name: "Нийгмийн даатгалын ерөнхий газар",
    date: "2023.1.3",
    count: 8,
  },
  {
    icon: "iso.svg",
    name: "Улсын бүртгэлийн ерөнхий газар",
    date: "2023.1.3",
    count: 15,
  },
  {
    icon: "iso.svg",
    name: "Хөдөлмөр халамж, үйлчилгээний ерөнхий газар",
    date: "2023.1.3",
    count: 12,
  },
  {
    icon: "iso.svg",
    name: "Гааль, татвар, санхүүгийн мэдээллийн технологийн төв",
    date: "2023.1.3",
    count: 16,
  },
  {
    icon: "iso.svg",
    name: "Нийгмийн даатгалын ерөнхий газар",
    date: "2023.1.3",
    count: 4,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 3,
  },
  {
    icon: "iso.svg",
    name: "Улсын бүртгэлийн ерөнхий газар",
    date: "2023.1.3",
    count: 10,
  },
  {
    icon: "iso.svg",
    name: "Хөдөлмөр халамж, үйлчилгээний ерөнхий газар",
    date: "2023.1.3",
    count: 10,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 11,
  },
  {
    icon: "iso.svg",
    name: "Гааль, татвар, санхүүгийн мэдээллийн технологийн төв",
    date: "2023.1.3",
    count: 12,
  },
  {
    icon: "iso.svg",
    name: "Улсын бүртгэлийн ерөнхий газар",
    date: "2023.1.3",
    count: 13,
  },
  {
    icon: "iso.svg",
    name: "Хөдөлмөр халамж, үйлчилгээний ерөнхий газар",
    date: "2023.1.3",
    count: 14,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 15,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 16,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 17,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 18,
  },
  {
    icon: "iso.svg",
    name: "Татварын ерөнхий газар",
    date: "2023.1.3",
    count: 19,
  },
];

export const profitData = [
  {
    id: "1",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "2",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "3",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "4",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "5",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "6",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "7",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "8",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "9",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
  {
    id: "10",
    name: "E-Mongolia",
    serviceName: "WS100009_checkOtp",
    description: "Нэг удаагийн нууц үг шалгах сервис",
    count: "12198",
  },
];
