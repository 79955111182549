import axios from "axios";

if (typeof window === 'undefined') {
    global.window = {}
}

export function getApiUrl() {
    let API_URL = process.env.REACT_APP_API_URL;
    return API_URL
}

export function getLocale() {
    // TODO stores and impl later
    return 'mn'
}

export const instance = axios.create({
    withCredentials: true
})
