import { cardData } from "../data/mockData";
import React, { useContext, useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { postAxios } from "../service/postAxios";
import { DateContext } from "../context/dateContext";
import ModalButton from "./ModalButton";
import { useLoading } from "../context/loaderContext";
import Spinner from "./Spinner";
import { Toast } from "./Toast";
import { ToastContainer } from "react-toastify";

export const Card = () => {
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cardData.slice(indexOfFirstItem, indexOfLastItem);
  const [alertData, setAlertData] = useState([]);
  const { startDate, endDate } = useContext(DateContext);
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    setIsLoading(true);
    changeSource();
  }, [startDate, endDate]);

  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const changeSource = () => {
    let dateRange = {
      startDate: startDate,
      endDate: endDate,
    };

    postAxios("/alert-sent-log/find-by-between-date-org-count", dateRange)
      .then((response) => {
        if (response.state === "SUCCESS") {
          setAlertData(response.data);
        } else setAlertData([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast("Алдааны имэйл илгээсэн үзүүлэлт дуудахад алдаа гарлаа: " + error, "error");
        // console.log("ERROR: " + error);
      });
  };
  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    if (!isNaN(selectedValue) && selectedValue > 0) {
      setItemsPerPage(selectedValue);
      paginate(1);
    }
  };
  const [jumpToPage, setJumpToPage] = useState("");

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage);
    if (
      !isNaN(pageNumber) &&
      pageNumber > 0 &&
      pageNumber <= Math.ceil(cardData.length / itemsPerPage)
    ) {
      paginate(pageNumber);
      setJumpToPage("");
    } else {
      console.log("Invalid page number");
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const emailDetail = [
    {
      title: "Огноо",
      dataIndex: "sentDate",
      key: "sentDate",
      width: "100px",
      render: (text) => <div style={{ wordBreak: "break-word" }}>{text}</div>,
    },
    {
      title: "Сервис нэр",
      dataIndex: "wsName",
      key: "wsName",
      width: "150px",
      render: (text) => <div style={{ wordBreak: "break-word" }}>{text}</div>,
    },
    {
      title: "Илгээсэн имэйл",
      dataIndex: "toMail",
      key: "toMail",
      width: "150px",
      render: (text) => <div style={{ wordBreak: "break-word" }}>{text}</div>,
    },
    {
      title: "Хүлээн авсан имэйл ",
      dataIndex: "fromMail",
      key: "fromMail",
      width: "150px",
      render: (text) => <div style={{ wordBreak: "break-word" }}>{text}</div>,
    },
    {
      title: "Текст",
      dataIndex: "mailBody",
      key: "mailBody",
      render: (text, record) => (
        <ModalButton data={record.mailBody} modalType="text" />
      ),
      responsive: ["sm"],
    },
  ];

  return (
    <>
      {isLoading ? (<Spinner />) : (
        <div>
          <ToastContainer />
          <div className="grid grid-cols-5 gap-4">
            {alertData.map((data) => (
              <div
                className="bg-card p-date rounded-2xl hover:text-white w-[264px] h-[124px] card border border-hidden">
                <div className="flex items-center gap-2">
                  <div
                    className="bg-[#1A1F37] w-8 h-8 rounded-lg p-1
                flex justify-center items-center img"
                  >
                    <img src={data.icon} alt="" className="w-4 h-4" />
                  </div>
                  <div
                    className="flex items-center bg-[#e31a1a26] gap-1 w-auto h-[22px]
                rounded-[4px] p-card attention"
                  >
                    <span className="text-xs text-[#E31A1A] font-bold leading-4">
                      {data.count}
                    </span>
                    <img src="attention.svg" alt="" />
                  </div>
                  <div
                    className="flex items-center  gap-1 w-3/12 h-[22px]
                rounded-[4px] p-card"
                  >
                    <ModalButton data={data.detail} column={emailDetail} />
                  </div>
                </div>
                <div>
                  <p className=" font-manrope font-medium text-xs text-[#aeb9e1] mt-2">
                    {data.name}
                  </p>
                </div>
                <div className="border border-[#56577a] mt-4 mb-2"></div>
                <div className="text-[#aeb9e1] text-[11px] font-normal leading-[15px]">
                  <p>
                    Огноо: {startDate} - {endDate}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-between gap-3 mt-2 px-6">
            <div className="flex items-center">
              <p className="text-[#a0aec0] font-medium text-[13px] leading-[18px]">
                Нийт {cardData.length}
              </p>
              <select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="border border-[#a0aec0]
          bg-[linear-gradient(0deg,#0F1535,#0F1535),linear-gradient(0deg,rgba(226,232,240,0.3),rgba(226,232,240,0.3))]
          rounded-md pr-[7px] pl-[10px] py-1 ml-2 text-sm font-bold text-[#A0AEC0]"
              >
                <option value={15}>15/хуудас</option>
                <option value={20}>20/хуудас</option>
                <option value={25}>25/хуудас</option>
                <option value={30}>30/хуудас</option>
              </select>
            </div>
            <div className="flex items-end justify-end gap-3">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className="border border-[#a0aec0] rounded-lg px-2 py-1"
              >
                <LeftOutlined color="#a0aec0" />
              </button>
              {cardData.length > itemsPerPage && (
                <ul className="flex gap-2">
                  {Array.from(
                    { length: Math.ceil(cardData.length / itemsPerPage) },
                    (_, i) => (
                      <li key={i} className="">
                        <button
                          className={`text-[#a0aec0] font-semibold text-sm py-[7px] text-center ${i + 1 === currentPage
                              ? "bg-[#0878ff] text-white px-3 py-1 rounded-lg"
                              : ""
                            }`}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              )}
              <button
                onClick={nextPage}
                disabled={indexOfLastItem >= cardData.length}
                className="border border-[#a0aec0] rounded-lg px-2 py-1"
              >
                <RightOutlined color="#a0aec0" size={6} />
              </button>
              <div className="flex gap-x-2">
                <button
                  onClick={handleJumpToPage}
                  className=" rounded-md px-[10px] text-[#a0aec0] font-medium text-[13px] py-1 ml-2"
                >
                  Очих
                </button>
                <input
                  type="text"
                  value={jumpToPage}
                  onChange={(e) => setJumpToPage(e.target.value)}
                  defaultValue={1}
                  className="border border-[#a0aec0] w-[40px] rounded-[8px] px-[10px] py-1 bg-[#0f1535]"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
