import Login from "../components/Login/Login.jsx";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import Logout from "../components/Login/Logout.jsx";
import { Layout } from "../components/Layout.jsx";
import Organization from "../components/Organization";
import Service from "../components/Service";
import User from "../components/User";
import { Profit } from "../components/Profit/Profit";
import ProtectedRoutes from "../components/ProtectedRoute";

export const routes = createBrowserRouter([
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Navigate to="/auth/login" />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/dashboard/*",
        element: (
          <Layout>
            <Dashboard />
          </Layout>
        ),
      },
      {
        path: "/organization",
        element: (
          <Layout>
            <Organization />
          </Layout>
        ),
      },
      {
        path: "/service",
        element: (
          <Layout>
            <Service />
          </Layout>
        ),
      },
      {
        path: "/profit",
        element: (
          <Layout>
            <Profit />
          </Layout>
        ),
      },
      {
        path: "/user",
        element: (
          <Layout>
            <User />
          </Layout>
        ),
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
]);
