import React, { useEffect, useState, useRef } from "react";
import PieChartComponent from "../pieChart";
import { Table, ConfigProvider, Button, Input, Space } from "antd";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { DateContext } from "../../context/dateContext";
import { postAxios } from "../../service/postAxios";
import ModalButton from "../ModalButton";
import Spinner from "../Spinner";
import { Toast } from "../Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {SearchOutlined, FilterOutlined, SortDescendingOutlined, SortAscendingOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export default function PieTable() {
  const [pieData, setPieData] = useState([]);
  const [pieDetailData, setPieDetailData] = useState([]);
  const [pieAllData, setPieAllData] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);
  const [chosenToggleIndex, chooseToggleIndex] = useState(0);
  const { startDate, endDate } = useContext(DateContext);
  const [loading, setLoading] = useState(false);
  let isService = false;
  const [pageSize, setPageSize] = useState(5);

  // Хайлт
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Хайх утга...`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
            background: "whitesmoke"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Хайх
          </Button>

          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Арилгах
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : "#AEB9E1",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    setLoading(true);
    changeSource(false);
  }, [startDate, endDate]);

  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const changeSource = async () => {
    setLoading(true);
    let dateRange = {
      startDate: startDate,
      endDate: endDate,
    };

    let url = !isService
      ? "/result-code-log/find-result-code-with-webservice"
      : "/result-code-log/find-result-code-with-client-org";

    await postAxios(url, dateRange)
      .then((response) => {
        if (response.state === "SUCCESS") {
          let data = response.data;
          data.map((big) => big.details.map((item, index) => {
            item.index = index + 1;
            if (!isService)
              item.clientOrgs.map((org, index) => org.index = index + 1);
            else
              item.webservices.map((service, index) => service.index = index + 1);
          }
          ));
          setPieData(data);
          setPieAllData(response.allData);
          setPieDetailData(data[0].details);
        } else {
          setPieData([]);
          setPieAllData([]);
          setPieDetailData([]);
          showToast("Мэдээлэл дамжуулсан тоо: " + response.message);
        }
      })
      .catch((error) => {
        showToast("Мэдээлэл дамжуулсан тоог дуудахад алдаа гарлаа: " + error, "error");
      });
    setLoading(false);
  };

  const ownerOrgFilters = [];
  const clientFilters = [];

  if (!loading && pieDetailData.length > 0) {
    pieDetailData.map((item) => {
      if (!ownerOrgFilters.some((existing) => existing.value == item.ownerOrgName)) {
        ownerOrgFilters.push({ text: item.ownerOrgName, value: item.ownerOrgName })
      }
      if (!clientFilters.some((existing) => existing.value == item.clientOrgName)) {
        clientFilters.push({ text: item.clientOrgName, value: item.clientOrgName })
      }

    })
  }

  const dataByService = [
    { title: "№", dataIndex: "index", key: "index" },
    {
      title: "Эзэмшигч байгууллага",
      dataIndex: "ownerOrgName",
      key: "ownerOrgName",
      filters: ownerOrgFilters,
      filterIcon: (filtered) => (
        <FilterOutlined
          style={{
            color: filtered ? '#1677ff' : "#AEB9E1",
          }}
        />
      ),

      filterSearch: true,
      onFilter: (value, record) => record.ownerOrgName == value,
    },
    {
      title: "Сервис нэр",
      dataIndex: "wsName",
      key: "wsName",
      ...getColumnSearchProps("wsName")
    },
    {
      title: "Тайлбар",
      dataIndex: "wsOperationDetail",
      key: "wsOperationDetail",
      ...getColumnSearchProps("wsOperationDetail")
    },
    { title: "Нийт",
      dataIndex: "total",
      key: "total",
      responsive: ["sm"],
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: { title: "Эрэмбэлэх" },
      sortIcon: ({ sortOrder }) => sortOrder == "ascend" ? <SortDescendingOutlined /> : <SortAscendingOutlined />
    },
    {
      title: "Үйлдэл",
      dataIndex: "more",
      key: "more",
      render: (text, record) => (
        <ModalButton data={record.clientOrgs} column={serviceDetail} />
      ),
    },
  ];

  const serviceDetail = [
    { title: "№", dataIndex: "index", key: "index" },
    {
      title: "Дуудсан байгууллага",
      dataIndex: "clientOrgName",
      key: "clientOrgName",
    },
    { title: "Тоо", dataIndex: "total", key: "total" },
  ];

  const orgDetail = [
    { title: "№", dataIndex: "index", key: "index" },
    {
      title: "Сервис",
      dataIndex: "wsName",
      key: "wsName",
      width: "150px",
      render: (text) => <div style={{ wordBreak: "break-word" }}>{text}</div>,
    },
    {
      title: "Тайлбар",
      dataIndex: "wsOperationDetail",
      key: "wsOperationDetail",
    },
    { title: "Тоо", dataIndex: "total", key: "total" },
  ];

  const dataByOrg = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Дуудсан байгууллага",
      dataIndex: "clientOrgName",
      key: "clientOrgName",
      filters: clientFilters,
      filterIcon: (filtered) => (
        <FilterOutlined
          style={{
            color: filtered ? '#1677ff' : "#AEB9E1",
          }}
        />
      ),

      filterSearch: true,
      onFilter: (value, record) => record.clientOrgName == value,
    },
    {
      title: "Эзэмшигч байгууллага",
      dataIndex: "ownerOrgName",
      key: "ownerOrgName",
      filters: ownerOrgFilters,
      filterIcon: (filtered) => (
        <FilterOutlined
          style={{
            color: filtered ? '#1677ff' : "#AEB9E1",
          }}
        />
      ),

      filterSearch: true,
      onFilter: (value, record) => record.ownerOrgName == value,
    },
    { title: "Нийт ",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: { title: "Эрэмбэлэх" },
      sortIcon: ({ sortOrder }) => sortOrder == "ascend" ? <SortDescendingOutlined /> : <SortAscendingOutlined />
    },
    {
      title: "Үйлдэл",
      dataIndex: "more",
      key: "more",
      render: (text, record) => (
        <ModalButton data={record.webservices} column={orgDetail} />
      ),
    },
  ];
  const toggleDisplay = (newValue) => {
    if (displayTable !== newValue) {
      isService = newValue;
      setDisplayTable(newValue);
      changeSource();
    }
  };

  const paginationConfig = {
    pageSize,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 20, 25],
    onShowSizeChange: (current, newSize) => setPageSize(newSize),
  };

  const resultChanger = (data, index) => {
    setPieDetailData(data);
    chooseToggleIndex(index);
  }

  return (
    <div className="text-white bg-table md:m-table md:ml-10 p-table rounded-2xl ">
      <div className="px-4">
        <div className="flex justify-between items-center">
          <h3 className="mt-[15px]">Мэдээлэл дамжуулсан тоо</h3>
          <span className="">
            <Link
              onClick={() => toggleDisplay(false)}
              className={`text-[#AEB9E1] font-semibold text-[14px] leading-[19.12px] text-left mr-2 ${!displayTable ? "bg-[#FFFFFF33] p-btn rounded-lg" : ""
                }`}
            >
              Сервисээр
            </Link>
            <Link
              onClick={() => toggleDisplay(true)}
              className={`text-[#AEB9E1] font-semibold text-[14px] leading-[19.12px] text-left mr-2 ${displayTable ? "bg-[#FFFFFF33] p-btn rounded-lg" : ""
                }`}
            >
              Дуудсан байгууллагаар
            </Link>
          </span>
        </div>
        <div className="text-[#AEB9E1] font-normal text-[14px] leading-[19.12px] text-left ">
          Огноо: {startDate} - {endDate}
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="bg-chart p-3 m-3 rounded-xl mt-2">
          <ToastContainer />
          <div className="xs:block xl:flex ">
            <PieChartComponent data={pieData} allData={pieAllData} toggler={resultChanger} chosenToggleIndex={chosenToggleIndex} />
            <ConfigProvider
              theme={{
                token: {
                  borderRadius: 2,
                  fontFamily: "manrope",
                  fontSize: "14px",
                  lineHeight: "19.12px",
                  paddingContentHorizontal: "10px",
                  colorBgContainer: "transparent",
                  colorText: "#AEB9E1",
                },
              }}
            >
              <Table style={{width: '100%'}}
                columns={!displayTable ? dataByService : dataByOrg}
                dataSource={pieDetailData}
                pagination={paginationConfig}
              />
            </ConfigProvider>
          </div>
        </div>
      )}
    </div>
  );
}
