import React, { useContext } from "react";
import { DatePicker, Space } from "antd";
import { DateContext } from "../context/dateContext";

const DateRangePicker = (props) => {
  const { dateRange, handleRangePickerChange } = useContext(DateContext);
  const { RangePicker } = DatePicker;
  return (
    <Space>
      <RangePicker
        value={dateRange}
        onChange={handleRangePickerChange}
        className="bg-[#0f1535] text-white rounded-[10px] border border-[#e2e8f066] cursor-pointer p-[4px] pl-[25px] py-[11px] font-manrope focus:bg-[#0f1535] hover:bg-[#0f1535] focus-within:bg-[#0f1535]"
        separator
        allowClear={false}
        suffixIcon
      />
    </Space>
  );
};

export default DateRangePicker;
