import React from "react";
import PieTable from "./PieTable";
import { BarCard } from "./BarCard";
export default function Dashboard() {
  return (
    <div className="cont dashboard">
        <>
          <PieTable />
          <BarCard />
        </>
    </div>
  );
}
