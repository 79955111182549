import {Button, ConfigProvider, Input, Modal} from "antd";
import React, {useState} from "react";
import { Toast } from "../Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getAxios} from "../../service/getAxios";

const ChangeEmailModal = (props) => {
  const [newEmail, setNewEmail] = useState("");
  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const closeModal = () => {
    setNewEmail("");
    props.setShowModal(false);
  };

  const resetEmail = () => {
    let param = {
      newEmail: newEmail,
      orgId: props.data.orgId,
    };

    getAxios("/organization/update-email", param).then((result) => {
      if (result.state === "SUCCESS") {
        showToast("Байгууллагын имэйл амжилттай солигдлоо", "success");
        closeModal();
      } else showToast("Байгууллагын имэйл солиход алдаа гарлаа", "error");
    });
  };

  const handleNewEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  return (
    <div>
      <ToastContainer />
      <ConfigProvider theme={{
        components: {
          Modal: {
            contentBg: "#181c32",

          },
        },
        token:{
        borderRadius:5,
          backdropFilter:"blur(0.4px)"}
        }}>
      <Modal
        centered
        width={400}
        open={props.open}
        onClose={() => closeModal()}
        onCancel={() => closeModal()}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button type="primary" style={{backgroundColor:"#1677ff", padding:'10px', paddingTop:'5px', boxShadow:'none'}}  onClick={() => resetEmail()}>
              Хадгалах
            </Button>
          </div>
        }
      >
        <div style={{ textAlign: "center", padding:'15px'}}>
          <p className='text-[16px] text-white mb-5 font-manrope '>ИМЭЙЛ СОЛИХ</p>
          <Input
              disabled="true"
              placeholder="Хуучин имэйл"
              style={{ margin: "5px",backgroundColor:'white',padding:'2px' ,marginBottom:'10px', color: '#000'}}
              value={props.data.email}
          />
          <Input
            placeholder="Шинэ имэйл"
            style={{ margin: "5px",backgroundColor:'white',padding:'2px' ,marginBottom:'20px', color: '#000'}}
            value={newEmail}
            onChange={handleNewEmailChange}
          />
        </div>
      </Modal>
      </ConfigProvider>
    </div>
  );
};

export default ChangeEmailModal;
