import React, { useEffect, useState, useRef } from "react";
import { Table, ConfigProvider, Button, Input, Space } from "antd";
import { getAxios } from "../../service/getAxios";
import ModalButton from "../ModalButton";
import { useLoading } from "../../context/loaderContext";
import Spinner from "../Spinner";
import { Toast } from "../Toast";
import { ToastContainer } from "react-toastify";
import { GetUserOrgId, GetUserRole } from "../../service/userInfo";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export default function Organization() {
    const [orgData, setOrgData] = useState([]);
    const { isLoading, setIsLoading } = useLoading();
    const [userOrgId, setUserOrgId] = useState('');
    const [isAdmin, setUserRole] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    // Хайлт
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Хайх утга...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        background: "whitesmoke"
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Хайх
                    </Button>

                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Арилгах
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : "#AEB9E1",
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const paginationConfig = {
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 20, 25],
        onShowSizeChange: (current, newSize) => setPageSize(newSize),
    };

    useEffect(() => {
        changeSource(false);
        setUserOrgId(GetUserOrgId());
        setUserRole(GetUserRole('super-admin'));
    }, []);

    const showToast = (message, toastType) => {
        Toast(message, toastType);
    };

    const changeSource = () => {
        setIsLoading(true);
        getAxios('/organization/find-all').then((result) => {
            if (result.state === 'SUCCESS') {
                setOrgData(result.data);
                setIsLoading(false);
            }
            else {
                setOrgData([]);
                setIsLoading(false);
            }
        }).catch((error) => {
            showToast("Байгууллагын мэдээлэл дуудах амжилтгүй боллоо: " + error, "error");
            setIsLoading(false);
        });

    };

    const columns = [
        { title: "№", dataIndex: "index", key: "index" },
        {
            title: "Нэр",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps("name")
        },
        {
            title: "Имэйл",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email")
        },
        {
            title: "Имэйл солих",
            dataIndex: "changeEmail",
            key: "changeEmail",
            render: (text, record) => (
                <ModalButton data={record} buttonType="changeEmail" style={{ visibility: (isAdmin || record.orgId === userOrgId) ? "visible" : "hidden" }} />
            )
        },
    ];

    return (
        <>
            {isLoading ? (<Spinner />) : (
                <div className="text-white bg-table md:m-table md:ml-10 p-table rounded-2xl ">
                    <ToastContainer />
                    <div className="px-4">
                        <div className="flex justify-between items-center">
                            <h3 className="mt-[15px]">Байгууллага</h3>
                        </div>
                    </div>
                    <div className="bg-chart p-3 m-3 rounded-xl mt-2">
                        <ConfigProvider
                            theme={{
                                token: {
                                    borderRadius: 2,
                                    fontFamily: "manrope",
                                    fontSize: "14px",
                                    lineHeight: "19.12px",
                                    paddingContentHorizontal: "10px",
                                    colorBgContainer: "transparent",
                                    colorText: "#AEB9E1",
                                },
                            }}
                            borderRadius={false}
                        >
                            <Table
                                columns={columns}
                                dataSource={orgData}
                                pagination={paginationConfig}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            )};
        </>
    );
}
