import React, {useEffect, useState} from "react";
import { SidebarToggleContext } from "../context/sidebarContext";
import { useContext } from "react";
import { useLoading } from "../context/loaderContext";
import Spinner from "./Spinner";
import { Toast } from "./Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Column} from "@ant-design/charts";
import {Button, ConfigProvider, Modal} from "antd";

export const GroupBarCharts = (props) => {
  const { isCollapsed } = useContext(SidebarToggleContext);
  const { isLoading, setIsLoading } = useLoading();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // setSelectedDate(startDate);
    setChartData(props.data);
  }, []);

  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const DemoColumn = () => {
    const config = {
      data: props.data,
      xField: 'name',
      yField: 'value',
      colorField: 'type',
      group: true,
      sort: {
        reverse: true,
        by: 'y',
      },
      label: {
        style: {
          fontWeight: 'bold',
          fill: '#000',
          labelTransform: 'rotate(-20)',
          position: 'inside'
        },
      },
      axis: {
        y: { labelFormatter: '~s' },
        x: {
          style: {
            labelTransform: 'rotate(30)',
            fill: '#fff',
            opacity: 1,
            fontSize: 12, // Optional: Customize font size
            fontWeight: 'bold', // Optional: Customize font weight
          },
        },
      },
    };
    return <Column {...config} />;
  };

  const closeModal=()=>
  {
    props.setShowModal(false);
  }

  return (
      <div>
        {isLoading ? (
            <Spinner />
        ) : (
            <ConfigProvider theme={{
              components: {
                Modal: {
                  // contentBg: "#181c32",
                },
              },
              token:{
                borderRadius:5,
                backdropFilter:"blur(0.4px)"}
            }}>
              <Modal
                  centered
                  width={'800px'}
                  open={props.open}
                  onClose={() => closeModal()}
                  onCancel={() => closeModal()}
                  footer={
                    <div style={{textAlign: "center"}}>
                      <Button type="primary" style={{backgroundColor:"#1677ff", padding:'10px', paddingTop:'5px', boxShadow:'none'}} onClick={closeModal}>Хаах</Button>
                    </div>
                  }
              >
                <ToastContainer/>
                <div style={{textAlign: "center"}}>
                  <p className='text-[16px] text-black mb-5 font-manrope '>ДЭЛГЭРЭНГҮЙ</p>
                  <DemoColumn
                      height={500}
                      width={600}
                      data={props.data}
                  />
                </div>
              </Modal>
            </ConfigProvider>
        )}
      </div>
  );
};
