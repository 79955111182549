import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Toast(message, toastType) {
  const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  toast[toastType](message, toastOptions);
}
