export function GetUserRole(searchRole) {
    const userData = localStorage.getItem('userData');
    if(userData !== null)
    {
        const obj = JSON.parse(userData);
        const roleAdmin = [];
        obj.role.forEach((item) => {
            roleAdmin.push(item.roleName);
        });
        if (roleAdmin.includes(searchRole))
            return true;
        else
            return false;
    }
    return false;
}

export function GetUserName() {
    const userData = localStorage.getItem('userData');
    if(userData !== null)
    {
        const obj = JSON.parse(userData);
        return obj.username;
    }
    return "Unknown";
}

export function GetUserOrgId() {
    const userData = localStorage.getItem('userData');
    if(userData !== null)
    {
        const obj = JSON.parse(userData);
        return obj.org[0].orgId;
    }
    return "Unknown";
}