import { NavLink, useLocation } from "react-router-dom";
import { Sidebar as ProSidebar } from "react-pro-sidebar";
import { SidebarToggleContext } from "../../context/sidebarContext";
import { useContext } from "react";
export default function Sidebar() {
  const { isCollapsed } = useContext(SidebarToggleContext);
  const location = useLocation();
  const navIcons = [
    {
      icon: "house.svg",
      href: "/dashboard",
      text: "Хянах самбар",
    },
    {
      icon: "Vector.svg",
      href: "/service",
      text: "Веб сервис",
    },
    {
      icon: "building.svg",
      href: "/organization",
      text: "Байгууллага",
    },
    {
      icon: "profit.svg",
      href: "/profit",
      text: "Тайлан",
    },
    {
      icon: "Group.svg",
      href: "/user",
      text: "Хэрэглэгч",
    },
  ];
  const logout = () => {
    localStorage.removeItem("userData");
    window.location.href = "/"
  }

  return (
    <div>
      <ProSidebar
        collapsed={isCollapsed}
        width="260px"
        height="1000px"
        collapsedWidth="95px"
        transitionDuration="200"
      >
        {!isCollapsed && (
          <div className="text-white">
            <div className=" bg-m fixed h-full right">
              <div className="p-logoo borde" style={{width: "280px"}}>
                <a href="/">
                  <img src="logo_big.png" alt="" />
                </a>
              </div>
              <div>
                <h3 className="p-big font-manrope text-[16px] font-bold leading-[22px]">
                  Үндсэн цэс
                </h3>
              </div>
              <div className="p-top">
                <ul className="">
                  {navIcons.map((item) => (
                    <li className="mx-[5%] text-[14px] font-manrope font-normal gap-5">
                      <NavLink
                        className="flex p-navi items-center gap-4 hover:bg-[#1a1f37] "
                        to={item.href}
                        isActive={() => location.pathname === item.href}
                        activeClassName="active"
                        style={({ isActive }) => {
                          return {
                            backgroundColor: isActive ? "#1A1F37" : "",
                            borderRadius: isActive ? "10px" : "10px",
                            paddingTop: isActive ? "12px" : "",
                            paddingBottom: isActive ? "12px" : "",
                            marginTop: isActive ? "6px" : "0px",
                            marginBottom: isActive ? "6px" : "0px",
                          };
                        }}
                      >
                        <div className="indi"></div>
                        <div className="bg-[#1a1f37] flex justify-center w-[26px] h-[26px] rounded-lg icon">
                          <img src={item.icon} alt="" className="w-[16px]" />
                        </div>
                        <div className="">
                          <p>{item.text}</p>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="p-navi">
                <button className="flex items-center text-red-500 gap-4" onClick={() => logout()}>
                  <span className="flex justify-center items-center bg-[#1A1F37] p-1 rounded-lg">
                    <img src="logout.svg" alt="" className="p-1" />
                  </span>
                  <p>Системээс гарах</p>
                </button>
              </div>
            </div>
          </div>
        )}
        {isCollapsed && (
          <div className="text-white">
            <div className=" bg-m fixed right h-full ">
              <div className="p-logo borde">
                <a href="/">
                  <img src="logo.png" alt="" style={{width: "75px", paddingLeft: "30px"}}/>
                </a>
              </div>
              <div>
                <h3 className="p-menu font-manrope text-[16px] font-bold leading-[22px] text-center">
                  Цэс
                </h3>
              </div>
              <div className="p-top pb-20">
                <ul className="">
                  {navIcons.map((item, index) => (
                    <li
                      key={index}
                      className="text-[14px] font-manrope font-normal"
                    >
                      <NavLink
                        className="flex p-navi rounded-[10px]"
                        to={item.href}
                        isActive={() => location.pathname === item.href}
                        activeClassName="active"
                        style={({ isActive }) => {
                          return {
                            backgroundColor: isActive ? "#1A1F37" : "",
                            marginTop: isActive ? "6px" : "0px",
                            marginBottom: isActive ? "6px" : "0px",
                          };
                        }}
                      >
                        <div
                          className="bg-[#1a1f37] flex justify-center
                         w-10 h-10 rounded-lg icon"
                        >
                          <img src={item.icon} alt="" className="w-[16px] " />
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="p-nav">
                <button
                  onClick={() => logout()}
                  className="flex items-center justify-center w-10
                 h-10 text-red-500 gap-4 bg-[#1A1F37] p-1 rounded-lg"
                >
                  <img src="logout.svg" alt="" className="" />
                </button>
              </div>
            </div>
          </div>
        )}
      </ProSidebar>
    </div>
  );
}
