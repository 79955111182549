import React, { useContext } from "react";
import { SidebarToggleContext } from "../../context/sidebarContext";
import { DateDisplay } from "../Date";
import { Navigate } from "react-router-dom";

export default function Navbar({ avatar, name }) {
  const { toggleSidebar } = useContext(SidebarToggleContext);
  const logout = () => {
    localStorage.removeItem("userData");
    window.location.href = "/";
  };
  let userdata;

  try {
    userdata = JSON.parse(localStorage.getItem("userData"));
  } catch (e) {
    localStorage.removeItem("userData");
    return <Navigate to="/" replace />;
  }

  if (!userdata.username) {
    userdata.username = "Хэрэглэгч";
  }

  return (
    <div
      className=" text-[#A0AEC0] pt-10 w-full flex
    justify-between borde pb-5 gap-5 px-10"
    >
      <div className="flex">
        <button onClick={toggleSidebar}>
          <img src="button.svg" alt="" className=" w-8" />
        </button>
      </div>
      <div className="flex items-center justify-between gap-4">
        <DateDisplay />
        <div
          className=" flex items-center bg-[linear-gradient(0deg,#0f1535,#0f1535)]
          border border-[#e2e8f066] p-[6px] rounded-[10px] shadow-date
          font-bold text-sm leading-[14.96px]"
        >
          {/* <img
            src={`${avatar ? { avatar } : "circle.svg"}`}
            alt=""
            className="w-[16px] mr-1"
          /> */}
          <div class="m-1 w-6 h-6 relative flex justify-center items-center rounded-full bg-[#004dd9] text-l text-white uppercase">
            {userdata.username[0]}
          </div>
          <p className="text-[#aeb9e1] text-sm font-manrope font-bold">
            {userdata.username}
          </p>
        </div>
        <button onClick={() => logout()}>
          <img src="log.svg" alt="" />
        </button>
      </div>
    </div>
  );
}
