import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "./Navbar/Navbar";

export const Layout = ({ children }) => {
  return (
    <div className="app">
      <Sidebar />
      <main className="content">
        <Navbar />
        {children}
      </main>
    </div>
  );
};
