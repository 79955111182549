import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "./DateRangePicker";

export const DateDisplay = () => {
  return (
    <div className="flex items-center">
      <button>
        <img src="filter.svg" alt="" />
      </button>
      <label htmlFor="">
        <DateRangePicker/>
      </label>
    </div>
  );
};
