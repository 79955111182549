const initialState = {
  loading: false,
  responseError: null,
  logginIn: false,
  data: null,
  userId: null,
  role: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER_START":
      return {
        ...state,
        loading: true,
      };

    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.data,
        role: action.data.role,
        logginIn: true,
        userId: action.data._id,
      };

    case "LOGIN_USER_ERROR":
      return {
        ...state,
        loading: false,
        responseError: action.err,
        logginIn: false,
      };

    case "LOGOUT":
      return {
        ...state,
        loading: false,
        userId: null,
        logginIn: false,
        reportError: null,
      };

    default:
      return state;
  }
};

export default reducer;
