import React, { useEffect, useState, useRef } from "react";
import { Table, ConfigProvider, Button, Input, Space } from "antd";
import { getAxios } from "../../service/getAxios";
import ModalButton from "../ModalButton";
import { GetUserName, GetUserRole } from "../../service/userInfo";
import { useLoading } from "../../context/loaderContext";
import { ToastContainer } from "react-toastify";
import Spinner from "../Spinner";
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export default function User() {
    const [userData, setUserData] = useState([]);
    const [username, setUsername] = useState('');
    const [userRole, setUserRole] = useState(false);
    const { isLoading, setIsLoading } = useLoading();

    // Хайлт
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Хайх утга...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        background: "whitesmoke"
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Хайх
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Арилгах
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : "#AEB9E1",
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    // Хайлт

    useEffect(() => {
        changeSource(false);
        setUsername(GetUserName());
        setUserRole(GetUserRole('super-admin'));
    }, []);

    const changeSource = () => {
        setIsLoading(true);
        getAxios('/user/find-all').then((result) => {
            if (result.state === "SUCCESS") {
                const array = [];
                result.data.forEach((item, index) => {
                    const resultData = {
                        "id": index + 1,
                        "userName": item.username,
                        "email": item.email,
                        "isActive": item.active ? "Тийм" : "Үгүй",
                        "orgName": item.org[0].name,
                        "roleName": item.role[0].roleName,
                        "roleDesc": item.role[0].roleDescription
                    };
                    array.push(resultData);
                });
                setUserData(array);
                setIsLoading(false);
            }
            else {
                setUserData([]);
                setIsLoading(false);
            }
        });
    };

    const roleFilters = [];
    const orgFilters = [];

    if (!isLoading && userData.length > 0) {
        userData.map((item) => {
            if (!orgFilters.some((existing) => existing.value == item.orgName)) {
                orgFilters.push({ text: item.orgName, value: item.orgName })
            }
            if (!roleFilters.some((existing) => existing.value == item.roleName)) {
                roleFilters.push({ text: item.roleName, value: item.roleName })
            }
        })
    }

    const columns = [
        { title: "№", dataIndex: "id", key: "id" },
        {
            title: "Хэрэглэгчийн нэр",
            dataIndex: "userName",
            key: "userName",
            ...getColumnSearchProps("userName")
        },
        {
            title: "Имэйл",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email")

        },
        {
            title: "Идэвхтэй эсэх",
            dataIndex: "isActive",
            key: "isActive",
            filters: [{ text: "Тийм", value: "Тийм" }, { text: "Үгүй", value: "Үгүй" }],
            filterIcon: (filtered) => (
                <FilterOutlined
                    style={{
                        color: filtered ? '#1677ff' : "#AEB9E1",
                    }}
                />
            ),
            onFilter: (value, record) => record.isActive == value,
        },
        {
            title: "Байгууллага",
            dataIndex: "orgName",
            key: "orgName",
            filters: orgFilters,
            filterIcon: (filtered) => (
                <FilterOutlined
                    style={{
                        color: filtered ? '#1677ff' : "#AEB9E1",
                    }}
                />
            ),

            filterSearch: true,
            onFilter: (value, record) => record.orgName == value,
        },
        {
            title: "Хэрэглэгчийн эрх",
            dataIndex: "roleName",
            key: "roleName",
            filters: roleFilters,
            filterIcon: (filtered) => (
                <FilterOutlined
                    style={{
                        color: filtered ? '#1677ff' : "#AEB9E1",
                    }}
                />
            ),

            filterSearch: true,
            onFilter: (value, record) => record.roleName == value,
        },
        {
            title: "Эрхийн тайлбар",
            dataIndex: "roleDesc",
            key: "roleDesc",
        },
        {
            title: "Нууц үг солих",
            dataIndex: "changePass",
            key: "changePass",
            render: (text, record) => (
                <ModalButton data={record} modalType="text" buttonType="change" style={{ visibility: record.userName === username || userRole ? "visible" : "hidden" }} />
            ),
        },
    ];

    return (
        <>
            {isLoading ? (<Spinner />) : (
                <div className="text-white bg-table md:m-table md:ml-10 p-table rounded-2xl ">
                    <ToastContainer />
                    <div className="px-4">
                        <div className="flex justify-between items-center">
                            <h3 className="mt-[15px]">Хэрэглэгч</h3>
                            <ModalButton buttonType="addUser" style={{ visibility: userRole ? "visible" : "hidden" }}/>
                        </div>
                    </div>
                    <div className="bg-chart p-3 m-3 rounded-xl mt-2">
                        <ConfigProvider
                            theme={{
                                token: {
                                    borderRadius: 2,
                                    fontFamily: "manrope",
                                    fontSize: "14px",
                                    lineHeight: "19.12px",
                                    paddingContentHorizontal: "10px",
                                    colorBgContainer: "transparent",
                                    colorText: "#AEB9E1",
                                },
                            }}
                            borderRadius={false}
                        >
                            <Table
                                columns={columns}
                                dataSource={userData}
                                pagination={{
                                    pageSize: 10,
                                }}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            )};
        </>
    );
}
