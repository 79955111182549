import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { routes } from "./router/Routes.jsx";
import {
  combineReducers,
  compose,
  legacy_createStore as createStore,
  applyMiddleware,
} from "redux";
import { Provider } from "react-redux";
import { thunk } from "redux-thunk";
import loginReducer from "./redux/loginReducer.jsx";
import { SidebarToggleProvider } from "./context/sidebarContext.js";
import { LoginProvider } from "./context/loginContext";
import { ModalProvider } from "./context/modalContext.js";
import { DateProvider } from "./context/dateContext.js";
import { LoadingProvider } from "./context/loaderContext.js";
import "./index.css";
import "./App.css";

const loggerMiddlaware = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action);
      return result;
    };
  };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENTION_COMPOSE__ || compose;

const reducers = combineReducers({
  loginReducer,
});

const middlewares = [loggerMiddlaware, thunk];
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
function Root() {
  return (
    <Provider store={store}>
      <LoadingProvider>
        <LoginProvider>
          <SidebarToggleProvider>
            <DateProvider>
              <ModalProvider>
                <RouterProvider router={routes} />
              </ModalProvider>
            </DateProvider>
          </SidebarToggleProvider>
        </LoginProvider>
      </LoadingProvider>
    </Provider>
  );
}
