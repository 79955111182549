import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import { BarCharts } from "../barChart";
import { Cards } from "./Cards";
import {DateContext} from "../../context/dateContext";

export const BarCard = () => {
  const [displayCard, setDisplayCard] = useState();
  const { startDate, endDate } = useContext(DateContext);

  const toggleDisplay = (newValue) => {
    if (displayCard !== newValue) {
      setDisplayCard(newValue);
    }
  };

  return (
    <div>
      <div
        className={
          "text-white bg-table md:m-table md:ml-10 p-table rounded-2xl"
        }
      >
        <div className="px-4">
          <div className="flex justify-between ">
            <h3 className="mt-[15px]">Алдааны имэйл илгээсэн үзүүлэлт </h3>
            <span className="">
              <Link
                onClick={() => toggleDisplay(false)}
                className={`text-[#AEB9E1] font-semibold text-[14px] leading-[19.12px] text-left mr-2 ${
                  !displayCard ? "bg-[#FFFFFF33] p-btn rounded-lg" : ""
                }`}
              >
                Графикаар
              </Link>
              <Link
                onClick={() => toggleDisplay(true)}
                className={`text-[#AEB9E1] font-semibold text-[14px] leading-[19.12px] text-left mr-2 ${
                  displayCard ? "bg-[#FFFFFF33] p-btn rounded-lg" : ""
                }`}
              >
                Байгууллагаар
              </Link>
            </span>
          </div>
          <div className="text-[#AEB9E1] font-normal text-[14px] leading-[19.12px] text-left">
            Огноо: {startDate} - {endDate}
          </div>
        </div>
        <div>{!displayCard ? <BarCharts /> : <Cards />}</div>
      </div>
    </div>
  );
};
