import React from "react";
import { Button, Divider, Form, Input } from "antd";
import logo from "../../assets/image/logo_big.png";
import emaLogo from "../../assets/image/emaLogo.svg";
import dashAnimation from "../../auth/dashboard.json";
import Lottie from "lottie-react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as actions from "../../redux/loginActions.js";
import { Navigate } from "react-router-dom";

import "./login.css";
import Spinner from "../Spinner/index.jsx";

const Login = (props) => {
  const onFinish = (values) => {
    props.login(values.username, values.password);
  };
  const date = new Date();
  let year = date.getFullYear();

  if (localStorage.getItem("userData")) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="main">
      <div className="containerr">
        <div className="contentt">
          <div className="imgg">
            <Lottie animationData={dashAnimation} style={{ height: "700px" }} />
            {}
          </div>
          <div className="bodyy">
            <div className="head">
              <img src={logo} alt="" className="logoo" />
            </div>
            <div className="form">
              <div className="loginTitle">Системд нэвтрэх</div>
              <Divider />
              {}
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                {}
                {props.responseError && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    {props.responseError}
                  </div>
                )}
                <Divider />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button bg-[#4096ff]"
                    block
                  >
                    Нэвтрэх
                  </Button>
                  {}
                </Form.Item>
              </Form>
              {}
              {}
            </div>
            {}
            {props.loading && <Spinner />}

            <div className="footer">
              <img src={emaLogo} alt="" className="emaLogo" />
              <p>
                © {year} он. И-Монгол Академи УТҮГ. Бүх эрх хуулиар
                хамгаалагдсан.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loginReducer.loading,
    responseError: state.loginReducer.responseError,
    data: state.loginReducer.data,
    role: state.loginReducer.role,
    userId: state.loginReducer.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) =>
      dispatch(actions.loginUser(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
