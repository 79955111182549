import React, {useContext, useEffect, useRef, useState} from "react";
import {ConfigProvider, Input, Space, Table} from "antd";
import { SidebarToggleContext } from "../../context/sidebarContext";
import { Button } from "antd";
import {
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import {postAxios} from "../../service/postAxios";
import {useLoading} from "../../context/loaderContext";
import {DateContext} from "../../context/dateContext";
import {Toast} from "../Toast";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Spinner";
import {ToastContainer} from "react-toastify";
import * as XLSX from 'xlsx';
import Highlighter from "react-highlight-words";

export const Profit = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { startDate, endDate } = useContext(DateContext);
  const [reportData, setReportData] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  // Хайлт
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
              ref={searchInput}
              placeholder={`Хайх утга...`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
                background: "whitesmoke"
              }}
          />
          <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Хайх
            </Button>


            <Button
                onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Арилгах
            </Button>

          </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : "#AEB9E1",
            }}
        />
    ),
    onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
  });

  const orgFilters = [];

  if (!isLoading && reportData.length > 0) {
    reportData.map((item) => {
      if (!orgFilters.some((existing) => existing.value == item.orgName)) {
        orgFilters.push({ text: item.orgName, value: item.orgName })
      }
    })
  }

  useEffect(() => {
    changeSource();
  }, [startDate, endDate]);

  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const paginationConfig = {
    pageSize,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 20, 25],
    onShowSizeChange: (current, newSize) => setPageSize(newSize),
  };

  const columns = [
    { title: "№", dataIndex: "index", key: "index" },
    { title: "Байгууллага",
      dataIndex: "orgName",
      key: "orgName",
      filters: orgFilters,
      filterIcon: (filtered) => (
          <FilterOutlined
              style={{
                color: filtered ? '#1677ff' : "#AEB9E1",
              }}
          />
      ),
      filterSearch: true,
      onFilter: (value, record) => record.orgName == value,
    },
    {
      title: "Сервис нэр",
      dataIndex: "wsName",
      key: "wsName",
      ...getColumnSearchProps("wsName"),
    },
    { title: "Тайлбар",
      dataIndex: "wsOperationDetail",
      key: "wsOperationDetail",
      ...getColumnSearchProps("wsOperationDetail"),},
    {
      title: "Тасалдсан тоо",
      dataIndex: "count",
      key: "count",
      sorter: (a, b) => a.count - b.count,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: { title: "Эрэмбэлэх" },
      sortIcon: ({ sortOrder }) => sortOrder == "ascend" ? <SortDescendingOutlined /> : <SortAscendingOutlined />
    },
  ];

  const changeSource = () => {
    setIsLoading(true);
    let dateRange = {
      startDate: startDate,
      endDate: endDate,
    };

    postAxios("/alert-result-code-log/find-by-between-date-report", dateRange)
        .then((response) => {
          if (response.state === "SUCCESS") {
            response.data.map((item, index) => { item.index = index + 1; });
            setReportData(response.data);
            setIsLoading(false);
          } else {
            setReportData([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          showToast("Мэдээлэл олдсонгүй: " + error, "error");
          setIsLoading(false);
        });
  };

  const exportToExcel = () =>{
    const data = reportData.map((item) => ({
      "№": item.index,
      "Байгууллага": item.orgName,
      "Сервисийн нэр": item.wsName,
      "Тайлбар": item.wsOperationDetail,
      "Тасалдсан тоо": item.count,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, 'alertReport' + startDate + '-' + endDate +'.xlsx');
  };

  const { isCollapsed } = useContext(SidebarToggleContext);
  return (
      <>
        { isLoading ? (<Spinner/>) :
        (<div
            className={`${
                isCollapsed
                    ? "text-white bg-table m-table p-table rounded-2xl "
                    : "text-white bg-table m-table ml-10 p-table rounded-2xl"
            }`}>
              <ToastContainer />
          <div className="px-4">
            <div className="flex justify-between">
              <h3 className="mt-[15px]">Тайлан</h3>
              <Button
                  icon={<DownloadOutlined/>}
                  size={"middle"}
                  className="bg-[#212c4d] border-[#636a80] text-[#a0aec0]"
                  onClick={exportToExcel}
              >
                Тайлан татах
              </Button>
            </div>
            <div className="text-[#AEB9E1] font-normal text-[14px] leading-[19.12px] text-left">
              Огноо: {startDate} - {endDate}
            </div>
          </div>
          <div className="bg-table">
            <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 2,
                    fontFamily: "manrope",
                    fontSize: "14px",
                    lineHeight: "19.12px",
                    paddingContentHorizontal: "10px",
                    colorBgContainer: "transparent",
                    colorText: "#AEB9E1",
                  },
                }}
                borderRadius={false}
            >
              <Table
                  columns={columns}
                  dataSource={reportData}
                  pagination={paginationConfig}
              />
            </ConfigProvider>
          </div>
        </div>
        )};
      </>
  );
};
