import {Button, Input, Modal, Space, Select, ConfigProvider} from 'antd';
import React, {useEffect, useState} from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {getAxios} from "../../service/getAxios";
import {postAxios} from "../../service/postAxios";
import Spinner from "../Spinner";
import { Toast } from "../Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUserModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [orgId, setOrgId] = useState('');
    const [roleName, setRoleName] = useState('');
    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newPassAgain, setNewPassAgain] = useState('');
    const [activeStatus, setActiveStatus] = useState(false);

    const showToast = (message, toastType)=>
    {
        Toast(message, toastType);
    }

    const closeModal=()=>
    {
        setUsername('');
        setUserEmail('');
        setNewPass('');
        setNewPassAgain('');
        setOrgData([]);
        setRoleData([]);
        setActiveStatus(false);
        props.setShowModal(false);
    }

    useEffect(() => {
        getOrgData();
        getRoleData();
    }, []);

    const getOrgData = () => {
        setLoading(true);
        getAxios('/organization/find-all').then((result) => {
            setLoading(false);
            if(result.state === 'SUCCESS')
            {
                let data = [];
                if(result.data !== null)
                {
                    for(var i=0; i<result.data.length; i++)
                    {
                        data.push({
                            'value': result.data[i].orgId,
                            'label': result.data[i].name,
                        });
                    }
                }
                setOrgData(data);
            }
            else
                setOrgData([]);
        });
    };

    const getRoleData = () => {
        setLoading(true);
        getAxios('/user-role/find-all').then((result) => {
            setLoading(false);
            if(result.state === 'SUCCESS')
            {
                let data = [];
                if(result.data !== null)
                {
                    for(var i=0; i<result.data.length; i++)
                    {
                        data.push({
                            'value': result.data[i].name,
                            'label': result.data[i].name,
                        });
                    }
                }
                setRoleData(data);
            }
            else
                setRoleData([]);
        });
    };

    const activeStatusData = [
        {
            'value': false,
            'label': 'Идэвхгүй',
        },
        {
            'value': true,
            'label': 'Идэвхтэй',
        }
    ];

    const createNewUser = () => {
        setLoading(true);

        if(username === null || username === "")
            showToast('Хэрэглэгчийн нэр хоосон байж болохгүй!', 'error');
        else if(userEmail === null || userEmail === "")
            showToast('Хэрэглэгчийн имэйл хоосон байж болохгүй!', 'error');
        else if(newPass === null || newPass === "")
            showToast('Нууц үг хоосон байж болохгүй!', 'error');
        else if(newPassAgain === null || newPassAgain === "")
            showToast('Нууц үг давтах хоосон байж болохгүй!', 'error');
        else if(orgId === null || orgId === "")
            showToast('Байгууллага хоосон байж болохгүй!', 'error');
        else if(roleName === null || roleName === "")
            showToast('Хэрэглэгчийн эрх хоосон байж болохгүй!', 'error');
        else if(activeStatus === null || activeStatus === "")
            showToast('Идэвхтэй эсэх хоосон байж болохгүй!', 'error');
        else
        {
            const json={
                "username": username,
                "email": userEmail,
                "password": newPass,
                "password_verify": newPassAgain,
                "orgIds": [orgId],
                "roleNames": [roleName],
                "active": activeStatus
            };

            postAxios('/auth/register', json).then((result) => {
                const jsonString = JSON.stringify(result);
                const parsedObject = JSON.parse(jsonString);
                setLoading(false);
                if(parsedObject.status === 201)
                {
                    showToast("Хэрэглэгч амжилттай бүртгэлээ.", 'success');
                    closeModal();
                }
                else
                    showToast(result.response.data.message, 'error');
            });
        }
    };

    const handleUsername = (e) => {
        setUsername(e.target.value);
    };

    const handleUserEmail = (e) => {
        setUserEmail(e.target.value);
    };

    const handleNewPassChange = (e) => {
        setNewPass(e.target.value);
    };

    const handleNewPassAgainChange = (e) => {
        setNewPassAgain(e.target.value);
    };

    const handleSelectOrg = (e) => {
        setOrgId(e);
    };

    const handleSelectRole = (e) => {
        setRoleName(e);
    };

    const handleSelectActiveStatus = (e) => {
        setActiveStatus(e);
    };

    return (
        <div>
            {loading ? (
                <Spinner />
            ) : (
            <ConfigProvider theme={{
                components: {
                    Modal: {
                        contentBg: "#181c32",

                    },
                },
                token:{
                    borderRadius:5,
                    backdropFilter:"blur(0.4px)"}
            }}>
            <Modal
                centered
                width={'300px'}
                open={props.open}
                onClose={() => closeModal()}
                onCancel={() => closeModal()}
                footer={
                    <div style={{textAlign: "center"}}>
                        <Button type="primary" style={{backgroundColor:"#1677ff", padding:'10px', paddingTop:'5px', boxShadow:'none'}} onClick={createNewUser}>Хадгалах</Button>
                    </div>
                }
            >
                <ToastContainer/>
                <div style={{textAlign: "center"}}>
                    <p className='text-[16px] text-white mb-5 font-manrope '>ХЭРЭГЛЭГЧ БҮРТГЭХ</p>
                    <Input
                        placeholder="Хэрэглэгчийн нэр"
                        style={{margin: "5px"}}
                        value={username}
                        onChange={handleUsername}
                    />
                    <Input
                        placeholder="Имэйл"
                        style={{margin: "5px"}}
                        value={userEmail}
                        onChange={handleUserEmail}
                    />
                    <Input.Password
                        placeholder="Нууц үг"
                        style={{margin: "5px"}}
                        value={newPass}
                        onChange={handleNewPassChange}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <Input.Password
                        placeholder="Нууц үг давтах"
                        style={{margin: "5px"}}
                        value={newPassAgain}
                        onChange={handleNewPassAgainChange}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <Space wrap>
                        <Select
                            defaultValue="Байгууллага"
                            style={{
                                width: 250,
                                margin: "5px"
                            }}
                            onChange={handleSelectOrg}
                            options={orgData}
                        />
                    </Space>
                    <Space wrap>
                        <Select
                            defaultValue="Эрх"
                            style={{
                                width: 250,
                                margin: "5px"
                            }}
                            onChange={handleSelectRole}
                            options={roleData}
                        />
                    </Space>
                    <Space wrap>
                        <Select
                            defaultValue="Идэвхтэй эсэх"
                            style={{
                                width: 250,
                                margin: "5px"
                            }}
                            onChange={handleSelectActiveStatus}
                            options={activeStatusData}
                        />
                    </Space>
                </div>
            </Modal>
            </ConfigProvider>
            )}
        </div>
    );
};

export default AddUserModal;


