import {createContext, useEffect, useState} from "react";
import moment from "moment/moment";
import dayjs from "dayjs";
import {getTimeDistance} from "../service/getDate";

export const DateContext = createContext()
export function DateProvider({ children }) {
  const displayFormat = 'YYYY-MM-DD';

  const week = getTimeDistance('week');
  const start = moment(week[1]).subtract(7, 'days').format(displayFormat);
  const end = moment(week[1]).format(displayFormat);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [dateRange, setDateRange] = useState([dayjs(start, displayFormat), dayjs(end, displayFormat)]);

  useEffect(() => {}, [week]);

  const handleRangePickerChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    setDateRange([dayjs(dateStrings[0], displayFormat), dayjs(dateStrings[1], displayFormat)]);
  };

  const value = {
    startDate,
    endDate,
    dateRange,
    handleRangePickerChange,
  };

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
}
