import { ConfigProvider, Table, Modal, Button } from "antd";

export const PopModal = (props) => {
  let content;
  if (props.modalType === "text")
    content = <div dangerouslySetInnerHTML={{ __html: props.data }}></div>;
  else
    content = (
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 10,
            fontFamily: "manrope",
            fontSize: "14px",
            lineHeight: "19.12px",
            paddingContentHorizontal: "10px",
            colorBgContainer: "transparent",
            colorText: "#AEB9E1",
            overflow: "scroll",
          },
        }}
        borderRadius={false}
      >
        <Table
          rowKey={props.rowKey}
          dataSource={props.data}
          columns={props.typeColumn}
          size={"big"}
        />
      </ConfigProvider>
    );

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#181c32",

            },
          },
          token: {
            borderRadius: 10,
            backdropFilter: "blue(0.4px)",
          },
        }}
      >
        <Modal
            width="auto"
          centered
          open={props.open}
          onClose={() => props.setShowModal(false)}
          onCancel={() => props.setShowModal(false)}
          footer={
            <>
              <Button
                onClick={() => props.setShowModal(false)}
                className="text-[#FFFFFFB2] border border-[#FFFFFFB2] p-more rounded-3xl hover:bg-[#FFFFFFB4]"
              >
                Хаах
              </Button>
            </>
          }
        >
          <div>{content}</div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};
