import {Button, ConfigProvider, Input, Modal} from "antd";
import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { postAxios } from "../../service/postAxios";
import { Toast } from "../Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangeUserPassModal = (props) => {
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassAgain, setNewPassAgain] = useState("");

  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const closeModal = () => {
    setCurrentPass("");
    setNewPass("");
    setNewPassAgain("");
    props.setShowModal(false);
  };

  const resetPass = () => {
    // setSaveLoading(true);

    const jsonPass = {
      currentPassword: currentPass,
      newPassword: newPass,
      newPasswordVerify: newPassAgain,
    };

    postAxios("/user/change-password", jsonPass).then((result) => {
      // setSaveLoading(false);
      if (result.state === "SUCCESS") {
        showToast(result.data, "success");
        closeModal();
      } else showToast(result.data, "error");
    });
  };

  const handleCurrentPassChange = (e) => {
    setCurrentPass(e.target.value);
  };

  const handleNewPassChange = (e) => {
    setNewPass(e.target.value);
  };

  const handleNewPassAgainChange = (e) => {
    setNewPassAgain(e.target.value);
  };

  return (
    <div>
      <ToastContainer />
      <ConfigProvider theme={{
        components: {
          Modal: {
            contentBg: "#181c32",

          },
        },
        token:{
        borderRadius:5,
          backdropFilter:"blur(0.4px)"}
        }}>
      <Modal
        centered
        width={400}
        open={props.open}
        onClose={() => closeModal()}
        onCancel={() => closeModal()}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button type="primary" style={{backgroundColor:"#1677ff", padding:'10px', paddingTop:'5px', boxShadow:'none'}}  onClick={() => resetPass()}>
              Хадгалах
            </Button>
          </div>
        }
      >
        <div style={{ textAlign: "center", padding:'15px'}}>
          <p className='text-[16px] text-white mb-5 font-manrope '>НУУЦ ҮГ СОЛИХ</p>
          <Input.Password
            placeholder="Хуучин нууц үг"
            style={{ margin: "5px",backgroundColor:'white',padding:'2px', color: '#000'}}
            value={currentPass}
            onChange={handleCurrentPassChange}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            placeholder="Шинэ нууц үг"
            style={{ margin: "5px",backgroundColor:'white',padding:'2px', color: '#000' }}
            value={newPass}

            onChange={handleNewPassChange}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            placeholder="Шинэ нууц үг давтах"
            style={{ margin: "5px",backgroundColor:'white',padding:'2px' ,marginBottom:'20px', color: '#000'}}
            value={newPassAgain}
            onChange={handleNewPassAgainChange}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />

        </div>
      </Modal>
      </ConfigProvider>
    </div>
  );
};

export default ChangeUserPassModal;
