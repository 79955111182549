import moment from 'moment';

export function getTimeDistance(type) {
    const now = new Date();
    const oneDay = 1000 * 60 * 60 * 24;

    if (type === 'today') {
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        return [moment(now), moment(now.getTime() + (oneDay - 1000))];
    }
    else if(type === 'week') {
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        const beginTime = now.getTime() - 6 * oneDay;
        return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
    }
    // else if(type === 'month') {
    //     const year = now.getFullYear();
    //     const month = now.getMonth();
    //     const nextDate = moment(now).add(1, 'months');
    //     const nextYear = nextDate.year();
    //     const nextMonth = nextDate.month();
    //
    //     return [
    //         moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
    //         moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    //     ];
    // }
    // else if(type === 'lastmonth') {
    //     const year = now.getFullYear();
    //     const month = now.getMonth();
    //     const nextDate = moment(now);
    //     const nextYear = nextDate.year();
    //     const nextMonth = nextDate.month();
    //
    //     return [
    //         moment(`${year}-${fixedZero(month)}-01 00:00:00`),
    //         moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    //     ];
    // }
    else if (type === 'all') {
        return [moment('2019-01-01 00:00:00'), moment(now).add(1, 'days')];
    }
    else
    {
        const year = now.getFullYear();
        return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
    }
}