import { getApiUrl, instance as Axios } from './base';

export const postAxios = (url, requestData) => {
    return Axios.post(getApiUrl() + url, requestData)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            if (err.response.data.error === 'Forbidden') {
                if (process.env.NODE_ENV == "production") {
                    localStorage.removeItem('userId');
                    localStorage.removeItem('userData');
                }
                // <Navigate to={'/auth/login'} />;
                // {
                //     location.reload();
                // }
            }
            return err;
        });
};
