import React from "react";
import { Card } from "../Card";

export const Cards = () => {
  return (
    <div className="mx-3">
      <Card />
    </div>
  );
};
