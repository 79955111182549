import React, { useRef, useEffect, useState } from "react";
import { Table, ConfigProvider, Button, Input, Space, } from "antd";
import { getAxios } from "../../service/getAxios";
import { useLoading } from "../../context/loaderContext";
import { Toast } from "../Toast";
import { ToastContainer } from "react-toastify";
import Spinner from "../Spinner";
import { SearchOutlined, FilterOutlined, SortDescendingOutlined, SortAscendingOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ModalButton from "../ModalButton";
export default function Service() {
    const [serviceData, setServiceData] = useState([]);
    const { isLoading, setIsLoading } = useLoading();
    const [pageSize, setPageSize] = useState(10);

    // Хайлт
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Хайх утга...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        background: "whitesmoke"
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Хайх
                    </Button>


                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Арилгах
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : "#AEB9E1",
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    useEffect(() => {
        changeSource(false);
    }, []);

    const paginationConfig = {
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 20, 25],
        onShowSizeChange: (current, newSize) => setPageSize(newSize),
    };

    const showToast = (message, toastType) => {
        Toast(message, toastType);
    };

    const changeSource = () => {
        setIsLoading(true);
        getAxios('/webservice/find-by-org-formatted').then((result) => {
            if (result.state === 'SUCCESS') {
                setIsLoading(false);
                setServiceData(result.data);
            }
            else {
                setIsLoading(false);
                setServiceData([]);
            }
        }).catch((error) => {
            showToast("Веб сервисийн мэдээлэл дуудах амжилтгүй боллоо: " + error, "error");
            setIsLoading(false);
        });
    };

    const operationDetailFilters = [];
    const orgFilters = [];

    if (!isLoading && serviceData.length > 0) {
        serviceData.map((item) => {
            if (!orgFilters.some((existing) => existing.value == item.ownerOrgName)) {
                orgFilters.push({ text: item.ownerOrgName, value: item.ownerOrgName })
            }
            if (!operationDetailFilters.some((existing) => existing.value == item.wsOperationDetail)) {
                operationDetailFilters.push({ text: item.wsOperationDetail, value: item.wsOperationDetail })
            }
        })
    }

    const columns = [
        { title: "№", dataIndex: "_id", key: "_id"},
        {
            title: "Сервис нэр",
            dataIndex: "wsOperationName",
            key: "wsOperationName",
            ...getColumnSearchProps("wsOperationName"),
        },
        {
            title: "Хувилбар",
            dataIndex: "wsVersion",
            key: "wsVersion",
            sorter: (a, b) => a.wsVersion.localeCompare(b.wsVersion),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: { title: "Эрэмбэлэх" },
            sortIcon: ({ sortOrder }) => sortOrder == "ascend" ? <SortDescendingOutlined /> : <SortAscendingOutlined />
        },
        {
            title: "WSDL",
            dataIndex: "wsWsdlEndpoint",
            key: "wsWsdlEndpoint",
            ...getColumnSearchProps("wsWsdlEndpoint"),
        },
        {
            title: "Тайлбар",
            dataIndex: "wsOperationDetail",
            key: "wsOperationDetail",
            filters: operationDetailFilters,
            filterIcon: (filtered) => (
                <FilterOutlined
                    style={{
                        color: filtered ? '#1677ff' : "#AEB9E1",
                    }}
                />
            ),
            filterSearch: true,
            onFilter: (value, record) => record.wsOperationDetail == value,
        },
        {
            title: "Эзэмшигч",
            dataIndex: "ownerOrgName",
            key: "ownerOrgName",
            filters: orgFilters,
            filterIcon: (filtered) => (
                <FilterOutlined
                    style={{
                        color: filtered ? '#1677ff' : "#AEB9E1",
                    }}
                />
            ),
            filterSearch: true,
            onFilter: (value, record) => record.ownerOrgName == value,
        },
        {
            title: "URL",
            dataIndex: "resourceUrl",
            key: "resourceUrl",
            ...getColumnSearchProps("resourceUrl"),

        },
        {
            title: "Оролт",
            dataIndex: "wsInput",
            key: "wsInput",
            render: (text, record) => (
                <ModalButton data={record.input} column={inputColumns} />
            ),
        },
        {
            title: "Гаралт",
            dataIndex: "wsOutput",
            key: "wsOutput",
            render: (text, record) => (
                <ModalButton data={record.output} column={outputColumns} />
            ),
        },
    ];

    const inputColumns = [
        { title: "№", dataIndex: "_id", key: "_id", render: (text, record, index) => index + 1 },
        {
            title: "Оролтын нэр",
            dataIndex: "wsInputName",
            key: "wsInputName",
        },
        {
            title: "Төрөл",
            dataIndex: "wsInputDatatype",
            key: "wsInputDatatype",
        },
        {
            title: "Тайлбар",
            dataIndex: "wsInputDetail",
            key: "wsInputDetail",
        },
    ];

    const outputColumns = [
        { title: "№", dataIndex: "_id", key: "_id", render: (text, record, index) => index + 1 },
        {
            title: "Гаралтын нэр",
            dataIndex: "wsResponseName",
            key: "wsResponseName",
        },
        {
            title: "Төрөл",
            dataIndex: "wsResponseDatatype",
            key: "wsResponseDatatype",
        },
        {
            title: "Тайлбар",
            dataIndex: "wsResponseDetail",
            key: "wsResponseDetail",
        },
    ];

    return (
        <>
            {isLoading ? (<Spinner />) : (
                <div className="text-white bg-table md:m-table md:ml-10 p-table rounded-2xl ">
                    <ToastContainer />
                    <div className="px-4">
                        <div className="flex justify-between items-center">
                            <h3 className="mt-[15px]">Веб сервис</h3>
                        </div>
                    </div>
                    <div className="bg-chart p-3 m-3 rounded-xl mt-2">
                        <ConfigProvider
                            theme={{
                                token: {
                                    borderRadius: 2,
                                    fontFamily: "manrope",
                                    fontSize: "14px",
                                    lineHeight: "19.12px",
                                    paddingContentHorizontal: "10px",
                                    colorBgContainer: "transparent",
                                    colorText: "#AEB9E1",
                                },
                            }}
                            borderRadius={false}
                        >
                            <Table
                                columns={columns}
                                dataSource={serviceData}
                                pagination={paginationConfig}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            )};
        </>

    );
}
