import { Button } from "antd";
import React, { useState } from "react";
import {  UserAddOutlined } from "@ant-design/icons";
import { PopModal } from "./Modal";
import ChangeUserPassModal from "./User/ChangeUserPassModal";
import {IoKey, IoMailOutline } from "react-icons/io5"
import ChangeEmailModal from "./User/ChangeEmailModal";
import AddUserModal from "./User/AddUserModal";
import {GroupBarCharts} from "./groupBarChart";

const ModalButton = (props) => {
  const [showModal, setShowModal] = useState(false);

  let content;
  let button;
  if (props.buttonType === "change") {
    content = <ChangeUserPassModal open={showModal} setShowModal={setShowModal} />;
    button = (
      <Button
        type="primary"
        className="shadow-none flex items-center bg-[#4096ff] px-9"
        style={props.style}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IoKey size={20} />
      </Button>
    );
  }
  else if (props.buttonType === "changeEmail") {
        content = <ChangeEmailModal open={showModal} setShowModal={setShowModal} data={props.data}/>;
        button = (
            <Button
                type="primary"
                className="shadow-none flex items-center bg-[#4096ff] px-9"
                style={props.style}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <IoMailOutline size={20} />
            </Button>
        );
    }
    else if (props.buttonType === "addUser") {
    content = <AddUserModal open={showModal} setShowModal={setShowModal} />;
    button = (
      <Button
          type="primary"
          className="shadow-none flex items-center bg-[#4096ff] px-9"
          style={props.style}
          onClick={() => {
          setShowModal(true);
        }}
      >
        {" "}
        <UserAddOutlined />{" "}
      </Button>
    );
  }
    else if (props.buttonType === "pieDetail") {
        content = <GroupBarCharts open={showModal} setShowModal={setShowModal} data={props.data}/>;
        button = (
            <Button
                type="primary"
                className="shadow-none flex items-center bg-[#4096ff] px-9"
                style={props.style}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                {" "}
                Дэлгэрэнгүй{" "}
            </Button>
        );
    }
    else {
    content = (
      <PopModal
        open={showModal}
        setShowModal={setShowModal}
        data={props.data}
        typeColumn={props.column}
        modalType={props.modalType}
        rowKey={props.rowKey}
      />
    );
    button = (
      <Button
        className="border text-white  p-more pt-1 rounded-2xl"
        style={props.style}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {" "}
        Дэлгэрэнгүй{" "}
      </Button>
    );
  }

  return (
    <div>
      <div>{button}</div>
      <div>{content}</div>
    </div>
  );
};

export default ModalButton;
