import { createContext, useState } from "react";

export const SidebarToggleContext = createContext();

export function SidebarToggleProvider({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const value = {
    isCollapsed,
    toggleSidebar,
  };

  return (
    <SidebarToggleContext.Provider value={value}>
      {children}
    </SidebarToggleContext.Provider>
  );
}
