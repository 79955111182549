import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { SidebarToggleContext } from "../context/sidebarContext";
import { useContext } from "react";
import { DateContext } from "../context/dateContext";
import { postAxios } from "../service/postAxios";
import { useLoading } from "../context/loaderContext";
import Spinner from "./Spinner";
import { Toast } from "./Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const BarCharts = () => {
  const { isCollapsed } = useContext(SidebarToggleContext);
  const { startDate, endDate } = useContext(DateContext);
  const [alertData, setAlertData] = useState([]);
  const { isLoading, setIsLoading } = useLoading();
  const [chosenRange, chooseRange] = useState([]);
  const [selectedDate, setSelectedDate] = useState(startDate);

  useEffect(() => {
    changeSource();
    // setSelectedDate(startDate);
  }, [startDate, endDate, selectedDate]);

  const showToast = (message, toastType) => {
    Toast(message, toastType);
  };

  const changeSource = () => {
    setIsLoading(true);
    let dateRange = {
      startDate: startDate,
      endDate: endDate,
    };

    postAxios("/alert-sent-log/find-by-between-date-org-detail", dateRange)
      .then((response) => {
        if (response.state === "SUCCESS") {
          setAlertData(response.data);
          chooseRange(response.data[0]);
          setIsLoading(false);
        } else {
          setAlertData([]);
          chooseRange([]);
          setIsLoading(false);
          showToast("Алдааны имэйл илгээсэн үзүүлэлт: " + response.message);
        }
      })
      .catch((error) => {
        showToast("Мэдээлэл олдсонгүй: " + error, "error");
        setIsLoading(false);
      });
  };

  const colors = [
    "#00C49F",
    "#0088fe",
    "#FF8042",
    "#FFBB28",
    "#0088aa",
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#edff5b",
    "#27aeef",
    "#281eea"
  ];

  const legend = (data, index) => {
    const counts = series.find(item => item.name == data.orgName).data;
    const total = counts.reduce((i, j) => i + j, 0);
    return (
      <div
        key={index}
        className="flex items-center justify-between mb-[5px]"
      >
        <div>
          <span
            className="w-[10px] h-[10px] mr-[5px] inline-block rounded-full"
            style={{
              backgroundColor: `${colors[index]}`, // Generate a random color
            }}
          />
          <span>{data.orgName}</span>
        </div>
        <div>
          <span>
            {data.count}
          </span>
          <span> - </span>
          <span>
            <b>{total}</b>
          </span>
        </div>
      </div>
    );
  };

  const dates = [];
  const series = [];

  alertData.forEach((day) => {
    dates.push(day.date);
    day.result.forEach((org) => {
      const existingOrg = series.find((item) => item.name === org.orgName);
      if (existingOrg) {
        existingOrg.data.push(org.count);
      } else {
        series.push({
          name: org.orgName,
          data: [org.count]
        });
      }
    });
  });

  const ApexOptions = {
    colors: colors,
    chart: {
      type: "bar",
      height: "100%",
      width: "30%",
      toolbar: {
        show: false,
      },
      stacked: true,
      events: {
        xAxisLabelClick: (event, chartContext, config) => {
          chooseRange(alertData[config.labelIndex]);
          setSelectedDate(dates[config.labelIndex]);
        }
      }
    },

    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "10%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 4,
    },
    xaxis: {
      categories: dates,
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },

    tooltip: {
      y: {
        formatter(val) {
          return ` ${val}`;
        },
      },
    },
  };
  return (
    <>
      {isLoading ? (<Spinner />) : (
        <div className="xs:block md:flex md:justify-between bg-table rounded-xl p-table md:m-table text-black">
          <ToastContainer />
          <span className={isCollapsed ? "w-4/6 right" : "w-9/12 right"}>
            <ReactApexChart
              series={series}
              options={ApexOptions}
              type="bar"
              height={410}
              width="100%"
            />
          </span>
          <span className="w-2/6 ">
            <div className="text-[#aeb9e1] p-3">
              <div
                className="flex items-center justify-between mb-[5px]">
                <div>
                  <b>{selectedDate}</b>
                </div>
                <div>
                  <span>
                    Хугацаанд
                  </span>
                  <span> - </span>
                  <span>
                    <b>Нийт</b>
                  </span>
                </div>
              </div>
              <br />
              {
                chosenRange && chosenRange.result && chosenRange.result.map((item, index) => legend(item, index))
              }
            </div>
          </span>
        </div>
      )}
    </>
  );
};
