import { getApiUrl, instance as Axios } from "../service/base.js";

export const loginUser = (username, password) => {
  return function (dispatch) {
    dispatch(loginUserStart());

    const data = {
      username,
      password,
    };

    Axios.post(getApiUrl() + "/auth/login", data)
      .then((response) => {
        localStorage.setItem("userId", response.data.data._id);
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        dispatch(loginUserSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(loginUserError(error.response.data.message));
      });
  };
};

export const loginUserStart = () => {
  return {
    type: "LOGIN_USER_START",
  };
};

export const loginUserSuccess = (data) => {
  return {
    type: "LOGIN_USER_SUCCESS",
    data,
  };
};

export const loginUserError = (err) => {
  return {
    type: "LOGIN_USER_ERROR",
    err,
  };
};

export const logout = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("userData");
  return {
    type: "LOGOUT",
  };
};
