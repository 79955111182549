import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import ModalButton from "./ModalButton";

const renderLegend = (data, toggler, chosenToggleIndex) => {
  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);
  let dataWithPercentages = data.sort((a, b) => b.value - a.value);
  dataWithPercentages = data.map((entry) => ({
    name: entry.name,
    value: entry.value,
    percentage: ((entry.value / totalValue) * 100).toFixed(2),
    color: entry.color
  }));

  return (
    <div className="text-[#AEB9E1]">
      {dataWithPercentages.map((entry, index) => (
        <div
          key={`legend-${index}`}
          className="flex justify-between items-center gap-8 cursor-pointer hover:text-white"
          onClick={() => toggler(data[index].details, index)}
        >
          <div
            className="flex items-center gap-4
          font-medium text-[13px] font-manrope leading-[32px]"
          >
            <div
              className=""
              style={{
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                borderRadius: "100%",
              }}
            ></div>
            <span style={chosenToggleIndex == index ? { color: "white", fontWeight: "900" } : {}}>{entry.name}</span>
          </div>
          <ul>
            <li
              key={index}
              className="flex font-manrope text-[13px]
              leading-[20px] font-semibold text-[#AEB9E1] gap-5"
            >
              {entry.value}
              <div
                className="text-[11px] leading-[15.03px] gap-1
             text-white p-percentage bg-[#FFFFFF33] rounded-lg"
              >
                {entry.percentage}%
              </div>
            </li>
          </ul>
        </div>
      ))}
        <div
            style={{backgroundColor: "rgba(255, 255, 255, 0.8)", color: "black", padding: "10px", borderRadius: "8px"}}>
            <div>ХУР resultCode-ын дэлгэрэнгүй тайлбарыг доорх</div>
            <div>холбоосоос авна уу.</div>
            <div><a href="https://developer.xyp.gov.mn/app/web/service-info" target="_blank"
                    style={{fontWeight: "bold", color: "blue", textDecoration: "underline"}}>https://developer.xyp.gov.mn/app/web/service-info</a></div>
        </div>
    </div>
  );
};

const PieChartWithPaddingAngle = (props) => {
    return (
        <div className="right xs:flex lg:block">
            <PieChart width={500} height={300} style={{pointerEvents: "none"}}>
                <Pie
                    data={props.data}
                    cx={225}
                    cy={120}
                    labelLine={false}
                    outerRadius={120}
                    innerRadius={80}
                    dataKey="value"
                    stroke="none"
                    strokeWidth={0}
                    style={{pointerEvents: "auto", cursor: "pointer", outline: "none"}}
                    onClick={(data, index) => props.toggler(data.details, index)}
                >
                    {props.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}
                              stroke={props.chosenToggleIndex == index ? "white" : "none"} strokeWidth={2}/>
                    ))}
                </Pie>
                <Tooltip/>
            </PieChart>
            <div className="xs:block lg:flex lg:justify-between lg:mt-[-30px]  ">
                {renderLegend(props.data, props.toggler, props.chosenToggleIndex, props.allData)}
            </div>
            <br/>
            <ModalButton buttonType="pieDetail" data={props.allData == null ? [] : props.allData}/>
        </div>
    );
};

export default PieChartWithPaddingAngle;
